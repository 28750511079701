import { usePageContext } from "../providers/PageContext";

const Footer = () => {
	const { isSuccessPage } = usePageContext();

	return (
		<>
			{!isSuccessPage && (
				<p className="text-white font-kievitProLight z-20 relative mt-[27px] mb-[64px]">
					Odesláním poptávky souhlasím se{" "}
					<a
						href="https://cpipg.com/en/data-protection-policy"
						className="underline-thin"
					>
						zpracováním osobních údajů.
					</a>
					<br />
					<span>
						Váš kontakt použijeme pouze pro nabídku bytů v Nové
						Zbrojovce.
					</span>
				</p>
			)}
			<h3 className="text-white font-trendSans text-[20px] leading-[20.18px] z-20 relative mt-[42px]">
				ČTVRŤ, KDE CHCETE BYDLET
			</h3>
		</>
	);
};

export default Footer;
