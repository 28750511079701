import { useForm } from "react-hook-form";
import { Input } from "./Input";
import { zodResolver } from "@hookform/resolvers/zod";
import { getFormSchema } from "../zod/schema";
import Checkbox from "./Checkbox";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef, useState } from "react";
import { usePageContext } from "../providers/PageContext";
import { url, sitekey } from "../enviroment";

const Form = () => {
	const {
		register,
		handleSubmit,
		control,
		getValues,
		formState: { errors },
	} = useForm({
		mode: "onChange",
		resolver: zodResolver(getFormSchema()),
	});

	const [isLoading, setIsLoading] = useState(false);
	const { setIsSuccessPage } = usePageContext();
	const recaptchaRef = useRef<any>();

	const onSubmit = async () => {
		const data = getValues();

		const disposition = ["1+kk", "2+kk", "3+kk", "4+kk"]
			.filter((key) => data[`dispozice_${key}`])
			.join(", ");

		const token = await recaptchaRef.current.executeAsync();
		recaptchaRef.current.reset();

		const payload = {
			firstname: data.firstName,
			lastname: data.lastName,
			email: data.email,
			phone: data.phone,
			message: data.message || "",
			token,
			disposition,
		};

		setIsLoading(true);

		try {
			const response = await fetch(`${url}/api/v1/lead`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					accept: "*/*",
				},
				body: JSON.stringify(payload),
			});

			if (response.ok) {
				(window as any).dataLayer = (window as any).dataLayer || [];
				(window as any).dataLayer.push({
					event: "submit_form",
					formData: payload,
				});

				setIsSuccessPage(true);
			} else {
				console.error("Error:", response.statusText);
			}
		} catch (error) {
			console.error("Error:", error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="relative z-30">
			<div className="grid md:grid-cols-2 gap-5 md:gap-10 mb-[42px]">
				<div className="space-y-5">
					<div className="mb-4 md:flex md:justify-between gap-5 grid">
						<Input
							register={register}
							control={control}
							placeholder="Jméno"
							registerName="firstName"
							errors={errors}
							type="text"
							inputClassName="w-full"
						/>
						<Input
							register={register}
							control={control}
							placeholder="Příjmení"
							registerName="lastName"
							errors={errors}
							type="text"
							inputClassName="w-full"
						/>
					</div>
					<div className="mb-4">
						<Input
							register={register}
							control={control}
							placeholder="E-mailová adresa"
							registerName="email"
							errors={errors}
							type="email"
						/>
					</div>
					<div className="mb-4">
						<Input
							register={register}
							control={control}
							placeholder="Telefonní číslo"
							registerName="phone"
							errors={errors}
							type="number"
						/>
					</div>
				</div>
				<Input
					register={register}
					control={control}
					placeholder="Zpráva"
					registerName="message"
					errors={errors}
					type="text"
					textArea
				/>
			</div>
			<div className="grid md:grid-cols-2 gap-5 md:gap-10 mb-4">
				<div>
					<p className="text-[12px] leading-[14.4px] text-gray-400 mb-4">
						DISPOZICE
					</p>
					<div className="flex flex-wrap gap-2">
						<Checkbox
							label="1+kk"
							registerName="dispozice_1+kk"
							register={register}
							control={control}
						/>
						<Checkbox
							label="2+kk"
							registerName="dispozice_2+kk"
							register={register}
							control={control}
						/>
						<Checkbox
							label="3+kk"
							registerName="dispozice_3+kk"
							register={register}
							control={control}
						/>
						<Checkbox
							label="4+kk"
							registerName="dispozice_4+kk"
							register={register}
							control={control}
						/>
					</div>
				</div>
				<div className="flex items-end">
					<button
						type="submit"
						disabled={isLoading}
						className="bg-[#C7A76B] text-white px-[32px] py-[18px] w-[208px] hover:bg-opacity-80 transition-all duration-300 ease-in-out"
					>
						{isLoading ? "ODESÍLÁM..." : "ODESLAT POPTÁVKU"}
					</button>
				</div>
			</div>
			{sitekey && (
				<ReCAPTCHA
					ref={recaptchaRef}
					className="z-20 relative"
					sitekey={sitekey}
					size="invisible"
				/>
			)}
		</form>
	);
};

export default Form;
