import { ComponentProps, FC } from "react";

type Props = ComponentProps<"svg">;

const LineFirst: FC<Props> = ({ ...props }) => {
	return (
		<svg
			className={props.className}
			width="113"
			height="103"
			viewBox="0 0 113 103"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.78199 172C99.6088 19.6928 13.455 154.308 111.282 2.00061"
				stroke="#C7A76B"
				strokeWidth="4"
			/>
		</svg>
	);
};

LineFirst.displayName = "LineFirst";

export default LineFirst;
