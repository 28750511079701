import { createContext, useState, useContext, ReactNode } from "react";

interface PageContextType {
	isSuccessPage: boolean;
	setIsSuccessPage: (value: boolean) => void;
}

interface PageProviderProps {
	children: ReactNode;
}

const PageContext = createContext<PageContextType>({
	isSuccessPage: false,
	setIsSuccessPage: () => {},
});

export const PageProvider = ({ children }: PageProviderProps) => {
	const [isSuccessPage, setIsSuccessPage] = useState<boolean>(false);

	return (
		<PageContext.Provider value={{ isSuccessPage, setIsSuccessPage }}>
			{children}
		</PageContext.Provider>
	);
};

export const usePageContext = () => useContext(PageContext);
