import React from "react";

const Gallery: React.FC = () => {
	const imageGridData = [
		{
			src: "https://novazbrojovka.cz/uploads/ck/3434610279_BlokA.jpg",
			caption: "10x ANO pro unikátní čtvrť 21. století",
			url: "https://novazbrojovka.cz/pojdte-bydlet-do-nove-zbrojovky",
		},
		{
			src: "https://novazbrojovka.cz/uploads/ck/9541618977_1bc40ff600a744f384f2b3391c3a4d6a.JPG",
			caption: "Život v největším brněnském brownfieldu",
			url: "https://novazbrojovka.cz/brownfield-nebo-greenfield-tak-kdepak-to-postavime",
		},
		{
			src: "https://novazbrojovka.cz/uploads/ck/3094637177_021032105241200NW0031.jpg",
			caption: "Udržitelný domov po všech stránkách",
			url: "https://novazbrojovka.cz/udrzitelny-rozvoj-nove-zbrojovky-vzdy-az-na-prvnim-miste-prestizni-certifikace-breeam-communities-doputovala-za-nami-do-brna",
		},
		{
			src: "https://novazbrojovka.cz/uploads/ck/9492207456_protipovodne1.jpeg",
			caption: "Relax v dvouhektarovém nábřežním parku s řekou",
			url: "https://novazbrojovka.cz/protipovodnova-opatreni-dalsiho-useku-brna-ochrani-160-hektaru-pozemku-a-zaruci-atraktivni-prostor-pro-obyvatele",
		},
		{
			src: "https://novazbrojovka.cz/uploads/220721_mangoshake_vytopna_square.jpg",
			caption: "Kultura na velkolepém náměstí se spoustou zeleně",
			url: "https://novazbrojovka.cz/hajecek-pokojicky-lesik-nova-zbrojovka-bude-mit-do-6-let-namesti",
		},
		{
			src: "https://novazbrojovka.cz/uploads/Architektura.jpg",
			caption: "Architektonická rozmanitost a kvalita",
			url: "https://novazbrojovka.cz/architektonicka-diverzita",
		},
		{
			src: "https://novazbrojovka.cz/uploads/SOA_1.jpg",
			caption: "Nová škola i školky se sportovišti",
			url: "https://novazbrojovka.cz/hola-skola-vola-soutez-urcila-podobu-dalsi-casti-nove-zbrojovky",
		},
	];

	return (
		<div className="flex justify-center md:justify-start">
			<div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-[40px]">
				{imageGridData.map((item, index) => (
					<div key={index} className="lg:max-w-[230px] z-30">
						<a
							href={item.url}
							target="_blank"
							rel="noopener noreferrer"
							className="hover:underline-thin"
						>
							<img
								src={item.src}
								alt={item.caption}
								className="aspect-[16/9] object-cover hover:opacity-[50%] transition-opacity duration-300 ease-in-out img-blur-effect"
							/>
							<div className="text-white py-[20px]">
								<p className="text-base leading-[20px] font-kievitProLight">
									{item.caption}
								</p>
							</div>
						</a>
					</div>
				))}
			</div>
		</div>
	);
};

export default Gallery;
