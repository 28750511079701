import React, { FC, useEffect, useRef } from "react";
import Logo from "../assets/Logo";
import classNames from "classnames";
import { usePageContext } from "../providers/PageContext";

type Props = {
	title: string | JSX.Element;
	subtitle: string;
	headerClassName?: string;
};

const Header: FC<Props> = ({ title, subtitle, headerClassName }) => {
	const { isSuccessPage } = usePageContext();
	const headerRef = useRef<any>(null);
	const hrRef = useRef<any>(null);

	useEffect(() => {
		if (headerRef.current && hrRef.current) {
			hrRef.current.style.width = `${headerRef.current.offsetWidth}px`;
		}
	}, [title, isSuccessPage]);

	return (
		<>
			<div className="flex justify-between w-full items-center pb-[22px] gap-5 pt-[1px]">
				<Logo className="relative left-1" />
			</div>

			<h1
				ref={headerRef}
				className={classNames(
					"text-white font-trendSans text-[32px] md:text-[52px] leading-[36px] md:leading-[56px] pb-5 md:max-w-[80%]",
					headerClassName
				)}
			>
				{title}
			</h1>
			<p className="text-white text-[20px] mb-[40px]">{subtitle}</p>
			{isSuccessPage && (
				<hr ref={hrRef} className="w-fit max-w-full mb-[41px]" />
			)}
		</>
	);
};

export default Header;
