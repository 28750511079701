import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import {
	Control,
	FieldErrors,
	FieldValues,
	UseFormRegister,
	useWatch,
} from "react-hook-form";

type Props = {
	register: UseFormRegister<FieldValues>;
	control: Control<FieldValues, any>;
	registerName: string;
	placeholder: string;
	errors: FieldErrors<FieldValues>;
	type: "text" | "email" | "number";
	textArea?: boolean;
	inputClassName?: string;
};

export const Input: FC<Props> = ({
	register,
	control,
	registerName,
	placeholder,
	errors,
	type,
	textArea,
	inputClassName,
}) => {
	const watchValue = useWatch({
		control,
		name: registerName,
	});
	const [rows, setRows] = useState(0);

	useEffect(() => {
		const updateRows = () => {
			if (window.innerWidth >= 768) {
				// Tailwind's md breakpoint is 768px
				setRows(0);
			} else {
				setRows(10);
			}
		};

		window.addEventListener("resize", updateRows);
		updateRows();

		return () => window.removeEventListener("resize", updateRows);
	}, []);

	const error = errors[registerName]?.message;
	const messagePlaceholder = `Dobrý den,\n\nmám zájem o jeden z prvních bytů v nově vznikající čtvrti Nová Zbrojovka.\n\n… doplňte vše, co bychom měli vědět …`;

	return (
		<div className={classNames("relative", inputClassName)}>
			{watchValue && (
				<p className="absolute top-[12px] left-[20px] text-[#A5ABB2] leading-[14.4px] text-[12px] mb-[1px]">
					{placeholder}
				</p>
			)}
			{error && (
				<p className="absolute translate -translate-y-1/2 top-1/2 right-[21px] text-[#A5ABB2] leading-[14.4px] text-[12px]">
					{error as any}
				</p>
			)}
			{textArea ? (
				<textarea
					rows={rows}
					className={classNames(
						"w-full bg-[#163447] text-white border-none outline-none font-light h-full resize-none",
						watchValue ? "pt-[28px] pb-[12px] px-5" : "p-5",
						"hover:bg-opacity-50 transition-all duration-300 ease-in-out"
					)}
					placeholder={messagePlaceholder}
					{...register(registerName)}
				/>
			) : (
				<input
					className={classNames(
						"w-full bg-[#163447] text-white border-none outline-none font-light h-full",
						watchValue ? "pt-[28px] pb-[12px] px-5" : "p-5",
						"hover:bg-opacity-50 transition-all duration-300 ease-in-out"
					)}
					type={type}
					placeholder={placeholder}
					{...register(registerName)}
				/>
			)}
		</div>
	);
};
