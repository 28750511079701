import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Gallery from "../Gallery";

function SuccessPage() {
	return (
		<>
			<Header
				title={
					<>
						DĚKUJEME ZA VÁŠ ZÁJEM. <br /> TEĎ UŽ S VÁMI POČÍTÁME!
					</>
				}
				headerClassName="xl:whitespace-nowrap"
				subtitle="Na váš e-mail jsme poslali potvrzení s úvodními informacemi. "
			/>
			<p className="text-white font-kievitPro text-[20px] leading-[24px] mb-[42px]">
				A proč chtít bydlet v Nové Zbrojovce?
			</p>
			<Gallery />
			<Footer />
		</>
	);
}

export default SuccessPage;
