import {
	Control,
	FieldValues,
	UseFormRegister,
	useWatch,
} from "react-hook-form";

type Props = {
	label: string;
	register: UseFormRegister<FieldValues>;
	control: Control<FieldValues, any>;
	registerName: string;
};

const Checkbox = ({ label, registerName, register, control }: Props) => {
	const watchValue = useWatch({
		control,
		name: registerName,
	});

	const checked = watchValue;

	return (
		<label className="inline-flex items-center cursor-pointer">
			<input
				type="checkbox"
				{...register(registerName)}
				checked={checked ? true : false}
				className="hidden"
			/>
			<span
				className={`relative w-[18px] h-[18px] inline-block border-none transition-colors duration-200 mr-[10px] ${
					checked ? "bg-[#C7A76B] border-none" : "bg-[#163447]"
				}`}
			>
				{checked && (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="absolute rotate-[-3deg] top-[2px]"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fillRule="evenodd"
							d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586 4.707 7.293a1 1 0 00-1.414 1.414l5 5a1 1 0 001.414 0l7-7a1 1 0 000-1.414z"
							clipRule="evenodd"
						/>
					</svg>
				)}
			</span>
			<span className="text-white mr-[10px]">{label}</span>
		</label>
	);
};

export default Checkbox;
