import React, { useEffect } from "react";
import { usePageContext } from "./providers/PageContext";
import SuccessPage from "./components/pages/SuccessPage";
import FormPage from "./components/pages/FormPage";
import { FC, ReactNode } from "react";
import LineFirst from "./assets/LineFirst";
import LineSecond from "./assets/LineSecond";

function App() {
	const { isSuccessPage } = usePageContext();

	useEffect(() => {
		if (isSuccessPage) {
			(window as any).dataLayer = (window as any).dataLayer || [];
			(window as any).dataLayer.push({
				event: "page_view",
				page: "success",
			});
		}
	}, [isSuccessPage]);

	if (isSuccessPage) {
		return (
			<Wrapper>
				<SuccessPage />
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			<FormPage />
		</Wrapper>
	);
}

export default App;

const Wrapper: FC<{ children: ReactNode }> = ({ children }) => (
	<div className="relative flex items-center justify-center min-h-screen font-kievitProRegular p-4">
		<div className="relative bg-[#0A293C] px-6 py-8 md:px-16 md:py-14 rounded-[10px] shadow-lg lg:w-3/4 w-full">
			<LineFirst className="absolute right-44 bottom-0 z-10" />
			<LineSecond className="absolute right-0 bottom-0 z-10" />
			{children}
		</div>
	</div>
);
