import { ZodType, z } from "zod";

export type FormData = {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	message?: string;
};

export const getFormSchema = (): ZodType<FormData> => {
	const validatePhone = (value: string) => !!value.match(/\+?[-\d\s]{9,20}/);

	return z.object({
		firstName: z.string().min(1, { message: "POVINNÉ" }),
		lastName: z.string().min(1, { message: "POVINNÉ" }),
		email: z
			.string()
			.min(1, { message: "POVINNÉ" })
			.email({ message: "POVINNÉ" }),
		phone: z.string().min(1, { message: "POVINNÉ" }).refine(validatePhone, {
			message: "POVINNÉ",
		}),
	});
};
