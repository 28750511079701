import { ComponentProps, FC } from "react";

type Props = ComponentProps<"svg">;

const LineSecond: FC<Props> = ({ ...props }) => {
	return (
		<svg
			className={props.className}
			width="238"
			height="459"
			viewBox="0 0 238 459"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.78205 531.001C100.609 378.693 245.245 154.307 343.072 2.00025"
				stroke="#C7A76B"
				strokeWidth="6"
			/>
		</svg>
	);
};

LineSecond.displayName = "LineSecond";

export default LineSecond;
