import Header from "../Header";
import Footer from "../Footer";
import Form from "../Form";

function FormPage() {
	return (
		<>
			<Header
				title="CHCI INFORMACE K PRODEJI BYTŮ MEZI PRVNÍMI"
				subtitle="Dejte nám o sobě vědět už nyní, ať si můžete vybrat byt přednostně!"
			/>
			<Form />
			<Footer />
		</>
	);
}

export default FormPage;
